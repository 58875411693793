export default {
  "general": {
    "text": "Заказчик",
    "items": {
      "companyName": {
        "text": "Организация",
      },
      "email": {
        "text": "Электронная почта",
      },
      "phone": {
        "text": "Номер телефона",
      },
      "address": {
        "text": "Юр. адрес",
      },
      "capital": {
        "text": "Уставной капитал",
      },
      "annualTurnover": {
        "text": "Годовой оборот ПНР",
      },
      "lastThreeYearsProjects": {
        "text": "Количество проектов, выполненных компанией за последние 3 года",
      },
      "experience": {
        "text": "Опыт оказания услуг в качестве подрядчика ПНР",
      },
      "generalQualificationMark": {
        "text": "Балл (общий по анкете)",
      },
    }
  },
}
